.contenedor{
    margin-left: 2%;
    width: 100%;
    overflow-x: hidden;
}
.uni{
    width: auto; 
}

.card-container{
    display: inline-block;
    height: auto;     
}

.cards{
    width: 110%;
    padding: 25px;   
}
.card-custom {
    width: 410px;
    height: 300px;
    overflow: hidden;
    min-height: 420px;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
    margin-bottom: 20px;
    margin: 10px;
}

.card-custom-img {
    width: 100%;
    height: 220px;
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-color: inherit;
}
.card-custom-img::after {
    position: absolute;
    content: '';
    top: 261px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-top-width: 40px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 545px;
    border-left-width: calc(575px - 5vw);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: inherit;
}
.card-custom-avatar {
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
    position: absolute;
    top: 140px;
    left: 1.25rem;
    width: 100px;
    height: 100px;
}
.description{
    overflow-y: auto;
    position: absolute; 
}

#ver{
    float: right;
    margin-top: 25%;
    font-size: 14px;
    color: darkgrey;
}

.btn-play{
    width: 50px;
    height: 50px;
    padding: 5px;
    margin: 2px;
    border-radius: 50px;
    font-size: 20px;
    background-color: #198de0;
    margin-left: 4px;
    margin-top: 15%;
}
.btn-play img{
  margin-left: 5px;
}
.btn-play:hover{
    background-color: #56b36e;
    transition: 0.3s;
    border-color: white;
    border-width: 1.5px;
}
/*FILTRO*/
.filter {
    padding-right: 290px !important;
  }
  .filter-dropdown {
    background: #ffffff;
    width: 350px;
    height: 50px;
    position: absolute;
    right: 20px;
    top: 10%;
    margin-top: -25px;
    padding: 0 20px;
    text-transform: none;
    z-index: 3;
    cursor: pointer;
  }
  .filter-dropdown .selected {
    line-height: 50px;
    padding-right: 50px;
  }
  .filter-dropdown .selected span.name {
    opacity: 0.5;
  }
  .filter-dropdown .arrow {
    line-height: 40px;
    position: absolute;
    right: 0;
    top: 5px;
    width: 50px;
    border-left: 1px solid #36b8c7;
    text-align: center;
  }
  .filter-dropdown .arrow:before {
    content: '\25BC';
    opacity: 0.5;
    font-size: 12px;
  }
  .filter-dropdown.open .arrow:before {
    content: '\25B2';
  }
  .filter-dropdown ul {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 50px;
    left: 0;
    width: 100%;
    margin-top: 3px;
    opacity: 0;
    height: 0px;
    overflow: hidden;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .filter-dropdown.open ul {
    opacity: 1;
    height: auto;
  }
  .filter-dropdown ul li {
    line-height: 50px;
    background: #ffffff;
    width: 100%;
    padding: 0 20px;
    cursor: pointer;
  }
  .filter-dropdown ul li:hover {
    background: #169db8;
    border-style: solid;
    border-width: 0.5px;
    border-color: grey;
  }
  /* Responsive */
  @media (max-width: 991px) {
    .isotope > article {
      width: 100% !important;
    }
    .filter {
      padding-right: 20px !important;
    }
    .filter-dropdown {
      width: 100%;
      position: relative;
      right: 0;
      top: 0;
      margin-top: 40px;
      text-align: left;
    }
  }