.nav{
    text-align: center;
}
.superior{
    width: 100%;
    height: 30px;
    background-image: url(TEXTURA_ST.png);
}
.logo{
    margin-left: 40%;
}

.justify-content-end{
    width: 20px;
}
#logo{
    width: 300px;
    height: auto;
}

